<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="detail_top">
            <div class="market_thumb" v-if="!$Util.isEmpty(market.mImage)">
              <swiper
                :slides-per-view="1"
                :centered-slides="true"
                :speed="500"
                :autoplay="{ delay: 3000 }"
              >
                <swiper-slide
                  v-for="(item, index) in market.mImage"
                  :key="index"
                >
                  <div class="img_area">
                    <img
                      :src="item"
                      alt="@/assets/static/images/no_image.png"
                    />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="market_thumb" v-else>
              <div class="img_area">
                <img src="@/assets/static/images/no_image.png" alt="" />
              </div>
            </div>
            <div class="party_ttl">{{ market.title }}</div>
            <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
              <span v-for="(item, index) in searchWords" :key="index">{{
                `#${item}`
              }}</span>
            </div>
          </div>
          <div class="lump bo_zero">
            <div class="table_type01">
              <dl>
                <dt>잔여수량/판매수량</dt>
                <dd>{{ market.remainQuantity }}개/{{ market.quantity }}개</dd>
              </dl>
              <dl>
                <dt>판매가(개당)</dt>
                <dd>{{ $Util.formatNumber2(market.price, { unit: '' }) }}원</dd>
              </dl>
            </div>
          </div>
          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>정산 예정금액(개당)</dt>
                <dd class="editor">
                  <div>
                    {{ $Util.formatNumber2(market.expectPrice, { unit: '' }) }}
                    원
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>
                  상품 소개 및 주의사항<span class="table_date">{{
                    $dayjs(market.contentsRegDt).format('YYYY-MM-DD')
                  }}</span>
                </dt>
                <dd class="editor">
                  <div>
                    {{ market.contents }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="market.type == '1'" class="lump">
            <div class="caution">
              <div class="inner">
                <dl>
                  <dt>구매자 요청정보</dt>
                  <dd>
                    <div class="req_options">
                      <div class="option" v-if="market.reqKakao == '1'">
                        카카오톡ID
                      </div>
                      <div class="option" v-if="market.reqPhone == '1'">
                        휴대폰번호
                      </div>
                      <div class="option" v-if="market.reqEmail == '1'">
                        이메일
                      </div>
                    </div>
                    <div class="color_secondary">
                      구매자가 작성한 정보는 판매내역 상세에서 확인 가능합니다.
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="lump bo_zero">
            <div class="table_type02">
              <dl>
                <dt>판매 내역</dt>
                <dd>
                  <div class="form" style="margin-top: 1rem;">
                    <ul class="form_ul">
                      <li
                        class="form_li"
                        v-for="(item, index) in computedPinList"
                        :key="index"
                      >
                        <div class="inline">
                          <label class="checkbox" style="flex: 0;">
                            <input
                              :disabled="item.payReqStatus != '1'"
                              v-model="checkList"
                              type="checkbox"
                              :value="item"
                              @change="onchange"
                            />
                            <span
                              class="label"
                              :class="{ disabled: item.payReqStatus != '1' }"
                            ></span>
                          </label>
                          <label
                            style="cursor: pointer;"
                            @click="fnViewDetail(item.seq, item.memberSeq)"
                            class="input"
                          >
                            <input
                              type="text"
                              class="input_btn"
                              :class="{
                                on: !(item.status == '2' || item.status == '5')
                              }"
                              style="cursor: pointer;"
                              :value="item.text"
                              disabled
                            />
                          </label>
                          <div
                            class="status"
                            :class="getStatusStyle(item.status, item.hasBadge)"
                          >
                            {{ item.statusNm }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </dd>
              </dl>
              <dl>
                <dd>
                  <div class="btn_wrap">
                    <button
                      class="btn"
                      v-if="market.status == '0' && showRequestApproval"
                      @click="fnMarketRequestApproval"
                    >
                      구매확정 요청
                    </button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="take_part">
            <div class="inner">
              <div class="take_part_ttl">
                <span>구매자</span>
              </div>
              <div class="take_part_list">
                <div
                  class="take_part_sec"
                  v-for="(item, index) in buyerInfo"
                  :key="index"
                  @click="fnIsShowABuyer(item)"
                >
                  <button class="take_part_btn">
                    <span class="buyer_info">
                      <span class="name">{{ item.nick }}</span>
                    </span>
                    <span>{{ item.quantity + ' 건' }}</span>
                  </button>
                  <div class="buyer_info_detail" :class="{ show: item.isShow }">
                    <div class="buyer_paper" v-show="item.isShow">
                      <dl
                        v-for="(useDt, useDtIndex) in item.useDtList"
                        :key="useDtIndex"
                      >
                        <dt style="display: inline; font-size: 1.4rem;">
                          {{ ('0' + (useDtIndex + 1)).slice(-2) }}
                        </dt>
                        <dd style="display: inline; margin-left: 1rem;">
                          {{
                            useDt == '-'
                              ? '기한 없음'
                              : `사용기한  ${useDt} 까지`
                          }}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con_btn" style="margin-top: 0;">
            <div v-if="market.status == '0'" class="btn_wrap">
              <button
                class="btn bo"
                v-if="Number(market.remainQuantity) > 0"
                @click="fnMarketClose"
              >
                판매 중지
              </button>
              <button class="btn bo" @click="fnEdit">
                마켓 수정
              </button>
            </div>
            <div v-if="market.status != '0'" class="btn_wrap">
              <button
                class="btn bo"
                v-if="
                  market.status == '1' ||
                    market.status == '2' ||
                    market.status == '3'
                "
                @click="fnRegenerate"
              >
                마켓 재등록
              </button>
              <button
                class="btn bo"
                v-if="market.status == '1' || market.status == '2'"
                @click="fnDelete"
              >
                마켓 삭제
              </button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>유의사항</dt>
                <dd>
                  <div>
                    * 캐시가 지급되는 기준은 구매자가 구매 확정 버튼을 실행할
                    경우 캐시가 지급됩니다.
                  </div>
                  <div>
                    * 구매확정 대기 상태일 경우 3일 뒤 자동 구매확정이 됩니다.
                  </div>
                  <div>
                    * 구매확정 요청은 결제 후 24시간이 지나면 가능합니다.
                  </div>
                  <div>
                    * 판매자가 등록한 상품 정보 및 거래에 대한 책임은 판매자에게
                    있으며, 허위 상품을 등록 시 형사 처분을 받을 수 있습니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-market-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
