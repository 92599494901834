import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    tabIndex: route.query.tabIndex,
    userData: computed(() => getters['user/getData']),
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.market.searchWord)) return []
      return state.market.searchWord.split(',')
    }),
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    form: {},
    params: route.query,
    market: {},
    buyerInfo: {},
    pinList: [],
    computedPinList: computed(() =>
      state.pinList.map(el => {
        let text
        if (el.useDt) {
          text = `사용기한 ${proxy.$dayjs(el.useDt).format('YYYY-MM-DD')} 까지`
        } else {
          text = '기한 없음'
        }
        return { ...el, text }
      })
    ),
    checkList: [],
    conflict: [],
    chatroomId: '',
    approval: false,
    approvalBadge: '0',
    approvalDt: '',
    price: computed(() => {
      // 1개당 금액 * 개수
      if (proxy.$Util.isEmpty(state.market)) return 0
      return state.market.quantity * Number(state.market.price)
    }),
    result: computed(() => {
      // price 에서 포인트 차감 금액
      let result = state.price
      if (!proxy.$Util.isEmpty(state.form.point) && Number(state.form.point)) {
        result -= state.form.point
      }
      return result
    }),
    fees: computed(() => {
      if (proxy.$Util.isEmpty(state.market)) return 0
      if (state.selectedPayType === proxy.$ConstCode.PAY_TYPE['1'].value) {
        // 신용카드 결제
        return Math.floor(Number(state.cardFee) * state.price)
      }
      // 위즈캐시 결제
      return Math.floor(Number(state.cashFee) * state.price)
    }),
    deleteShow: computed(() => {
      if (proxy.$Util.isEmpty(state.pinList)) return false
      return state.pinList.every(pin => pin.status == 0)
    }),
    showRequestApproval: computed(() => {
      if (proxy.$Util.isEmpty(state.pinList)) return false
      return state.pinList.some(pin => pin.payReqStatus == 1)
    })
  })

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: state.market.marketNo
      }
    })
  }

  const fnRegenerate = async () => {
    await router.replace({
      name: 'my-page-market-edit-index',
      params: { regenerate: true },
      query: {
        marketNo: state.market.marketNo
      }
    })
  }

  const fnPage = async () => {
    await router.push({ path: '/market/pay', query: { ...state.form } })
  }

  const fnModalAddInfo = async () => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_ADD_INFO,
      data: {},
      callback: async () => {}
    }
    await fnModalAlert(payload)
  }

  const fnBuyerView = async ({ memberSeq }) => {
    await router.push({
      path: '/my-page/market/member',
      query: {
        marketNo: state.market.marketNo,
        memberSeq
      }
    })
  }

  const fnDetail = async () => {
    const res = await proxy.$MarketSvc.postMyPageSaleMarketDetail(state.params)
    if (res.resultCode === '0000') {
      state.market = res.market
      state.buyerInfo = res.buyerInfo
      state.pinList = res.pin
    } else {
      alert(res.resultMsg)
    }
  }

  const fnDelete = async () => {
    if (!confirm('상품을 삭제 하시겠습니까?')) return
    const res = await proxy.$MarketSvc.postMyPageMarketDelete(state.params)
    if (res.resultCode === '0000') {
      alert('삭제되었습니다.')
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }

  // TODO: 분쟁 취소?
  const fnCancelArgument = async () => {
    // const res = await proxy.$MarketSvc.postMyPageMarket
  }

  // TODO: 결제 승인?
  const fnConfirm = async () => {
    //   const res
  }

  const fnWizztalk = async () => {
    const params = {}
    params.marketNo = state.params.marketNo
    params.memberSeq = state.member[0].memberSeq
    if (proxy.$Util.isEmpty(state.chatroomId)) {
      const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
      if (res.resultCode === '0000') {
        params.chatroomId = res.chatroomId
        await router.push({
          path: '/market/wizz-talk',
          query: params
        })
      } else {
        alert(res.resultMsg)
      }
    } else {
      const params = {}
      params.marketNo = state.params.marketNo
      params.memberSeq = state.member[0].memberSeq
      params.chatroomId = state.chatroomId
      await router.push({
        path: '/market/wizz-talk',
        query: params
      })
    }
  }

  const fnIsShowABuyer = ABuyer => {
    if (proxy.$Util.isEmpty(ABuyer.isShow)) {
      ABuyer.isShow = true
    } else {
      ABuyer.isShow = !ABuyer.isShow
    }
  }

  const getStatusStyle = (status, hasBadge) => {
    let style = {}
    if (status == 2 || status == 4 || status == 5) {
      style.done = true
    } else if (status == 1) {
      style.waiting = true
    } else if (status == 0) {
      style.normal = true
    } else {
      style.conflict = true
    }

    if (hasBadge == '1') style.new = true

    return style
  }

  const fnMarketClose = async () => {
    if (
      !confirm('판매중지할 경우 물품을 재등록 하셔야됩니다. 중지 하시겠습니까?')
    )
      return
    const res = await proxy.$MarketSvc.postMyPageSaleMarketClose({
      marketNo: state.market.marketNo
    })
    if (res.resultCode === '0000') {
      alert('판매중지되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMarketRequestApproval = async () => {
    if (proxy.$Util.isEmpty(state.checkList)) {
      alert('구매 확정 요청 하실 상품을 선택해주세요.')
      return
    }
    if (
      !confirm(
        `선택 된 상품 (${state.checkList.length}개)를\n구매 확정 요청하시겠습니까?`
      )
    )
      return

    const params = { ...state.params }
    delete params.tabIndex
    params.pinSeqList = state.checkList.reduce((pv, cv) => [...pv, cv.seq], [])
    const res = await proxy.$MarketSvc.postMyPageMarketRequestApproval(params)
    if (res.resultCode === '0000') {
      alert('구매 확정 요청되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnViewDetail = (pinSeq, memberSeq) => {
    let query = { ...state.params, memberSeq, pinSeq }
    delete query.tabIndex
    router.push({
      name: 'my-page-market-view-detail-index',
      query
    })
  }

  const init = async () => {
    await fnDetail()
  }

  init()

  return {
    ...toRefs(state),
    fnPage,
    fnModalAddInfo,
    fnEdit,
    fnBuyerView,
    fnDetail,
    fnDelete,
    fnCancelArgument,
    fnWizztalk,
    fnConfirm,
    getStatusStyle,
    fnIsShowABuyer,
    fnViewDetail,
    fnMarketRequestApproval,
    fnMarketClose,
    fnRegenerate
  }
}
